<template>
	<e-select
		v-model="seasonSelected"
		id="seasons"
		track-by="name"
		label="name"
		class="primary"
		:options="seasons"
		:searchable="true"
		:show-labels="false"
		:allow-empty="false"
		sort-desc
	>
		<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
		<template slot="noOptions">{{ $t('global.list_empty') }}</template>
	</e-select>
</template>

<script>
import Gynecologie from '@/mixins/Gynecologie'

export default {
	name: 'SeasonInput',
	mixins: [Gynecologie],
	props: {
		value: Object,
		selectPreviousDefault: {
			type: Boolean,
			default: () => {
				return false
			}
		}
	},
	data () {
		return {
			seasons: [],
		}
	},
	created() {
		this.init_component()
	},
	methods: {
		async init_component() {
			const seasons = await this.loadSeasons(false, true)
			if(seasons) {
				for(let i = 0; i < seasons.length; i++) {
					this.seasons.push({
						name: seasons[i].season_label, 
						id: seasons[i].season_id,
						begin: seasons[i].season_start,
						end: seasons[i].season_end,
						season_id: seasons[i].season_id,
					})
					if(seasons[i].season_default) {
						if(this.selectPreviousDefault && i > 0) {
							this.seasonSelected = {
								name: seasons[i-1].season_label, 
								id: seasons[i-1].season_id,
								begin: seasons[i-1].season_start,
								end: seasons[i-1].season_end,
								season_id: seasons[i-1].season_id,
							}
						}
						else {
							this.seasonSelected = {
								name: seasons[i].season_label, 
								id: seasons[i].season_id,
								begin: seasons[i].season_start,
								end: seasons[i].season_end,
								season_id: seasons[i].season_id,
							}
						}
					}
				}
			}
		}
	},
	computed: {
		seasonSelected: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
				this.$emit('change', val)
			}
		}
	}
}
</script>
